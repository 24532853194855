<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('bsri_config.mill_subzone_center_unit_no_wise_cda_mapping') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="cda-mapping" :class="'btn btn-success text-light'">{{ $t('bsri_config.mill_subzone_center_unit_no_wise_cda_mapping') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6">
                          <ValidationProvider name="Mill type" vid="mill_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="mill_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('bsri_config.mill_type') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.mill_type_id"
                                  :options="millTypeList"
                                  id="mill_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6">
                            <ValidationProvider name="Mill Name" vid="mill_name_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mill_name_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{ $t('bsri_config.mill_name') }} <span class="text-danger">*</span>
                                    </template>
                                <b-form-select
                                    plain
                                    v-model="formData.mill_info_id"
                                    :options="millInfoList"
                                    id="mill_name_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                      </b-row>
                      <fieldset class="p-2 w-100">
                        <legend class="px-2 w-50 shadow-sm">{{ $t('bsri_config.mill_subzone_center_unit_no_wise_cda_mapping') }}</legend>
                        <div class="col-md-12">
                          <b-overlay :show="load">
                            <b-row v-if="formData.details.length > 0">
                              <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="mt-3">
                                <b-table-simple striped bordered small>
                                  <tr>
                                      <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                      <b-th class="text-center">{{ $t('bsri_config.subzone_office_name') }}</b-th>
                                      <b-th class="text-center">{{ $t('bsri_config.center_name') }}</b-th>
                                      <b-th class="text-center">{{ $t('bsri_config.unit_no') }}</b-th>
                                      <b-th class="text-center">{{ $t('globalTrans.name') }}</b-th>
                                  </tr>
                                  <tr v-for="(item,index) in formData.details" :key="index">
                                      <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                      <b-td class="text-center">
                                        <b-form-select
                                            plain
                                            v-model="item.subzone_office_id"
                                            :options="subZoneList"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                      </b-td>
                                      <b-td class="text-center">
                                        <b-form-select
                                            plain
                                            v-model="item.center_id"
                                            :options="centerList"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                      </b-td>
                                      <b-td class="text-center">
                                        <b-form-select
                                            plain
                                            v-model="item.unit_id"
                                            :options="unitList"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                      </b-td>
                                      <b-td class="text-center"></b-td>
                                  </tr>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                            <template v-else>
                              <h5 class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</h5>
                            </template>
                          </b-overlay>
                        </div>
                      </fieldset>
                      <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          </div>
                        </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { districtApprovalCommitteeResolutionStore, districtApprovalCommitteeResolutionUpdate, userList, districtApprovalCommitteeResolutionShow, allocationListResolutionList } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.id) {
      this.getFormData()
    }
    this.allocationData()
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        mill_type_id: 0,
        mill_info_id: 0,
        details: []
      },
      alloData: [],
      subsidyList: [],
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl
    }
  },
  computed: {
    millTypeList: function () {
      const millTypeList = this.$store.state.incentiveGrant.commonObj.millTypeList.filter(item => item.status === 1)
      return millTypeList
    },
    millInfoList: function () {
      const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      return millInfoList
    },
    subZoneList: function () {
      const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item)
      return subZoneList
    },
    centerList: function () {
      const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item)
      return centerList
    }
  },
  watch: {
    'formData.mill_info_id': function (newValue, OldValue) {
      this.getAlloInfo()
    }
  },
  methods: {
    getSubsidyList (subsidyTypeId) {
      const subsidyList = this.$store.state.incentiveGrant.commonObj.subsidyList.filter(item => item.status === 1 && item.subsidy_type_id === subsidyTypeId)
      return subsidyList
    },
    async getFormData () {
      this.loading = true
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, districtApprovalCommitteeResolutionShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
      }
      this.loading = false
    },
    async allocationData () {
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, allocationListResolutionList)
      if (!result.success) {
        this.alloData = []
      } else {
        this.alloData = result.data
      }
    },
    async getAlloInfo () {
      this.load = true
      const result = await RestApi.getData(authServiceBaseUrl, userList, this.formData)
      if (!result.success) {
        this.formData.details = []
      } else {
        const listData = result.data.map(item => {
          const zoneObj = this.$store.state.incentiveGrant.commonObj.subZoneList
          const zoneObjData = {
            zone_id: zoneObj
          }
          const centerObj = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item)
          const centerObjData = {
            centerList: centerObj
          }
          const unitObj = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item)
          const unitObjData = {
            unitList: unitObj
          }
          return Object.assign({}, item, zoneObjData, centerObjData, unitObjData)
        })
        this.formData.details = listData
      }
      this.load = false
    },
    getCustomDataList (data) {
      const listData = data.map(item => {
        const matTypeObj = this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.find(agMaterialTypeList => agMaterialTypeList.value === parseInt(item.mat_type))
        const matTypeObjData = {}
        if (typeof matTypeObj !== 'undefined') {
          matTypeObjData.mat_type_en = matTypeObj.text_en
          matTypeObjData.mat_type_bn = matTypeObj.text_bn
        } else {
          matTypeObjData.mat_type = ''
          matTypeObjData.mat_type_bn = ''
        }
        const matObj = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(agMaterialList => agMaterialList.value === parseInt(item.mat_id))
        const matObjData = {}
        if (typeof matObj !== 'undefined') {
          matObjData.mat = matObj.text_en
          matObjData.mat_bn = matObj.text_bn
        } else {
          matObjData.mat = ''
          matObjData.mat_bn = ''
        }
        const cropListObj = this.$store.state.incentiveGrant.commonObj.cropList.find(cropList => cropList.value === parseInt(item.crop_id))
        const cropListObjData = {}
        if (typeof cropListObj !== 'undefined') {
          cropListObjData.crop = cropListObj.text_en
          cropListObjData.crop_bn = cropListObj.text_bn
        } else {
          cropListObjData.crop = ''
          cropListObjData.crop_bn = ''
        }
        const agMaterialUnitListObj = this.$store.state.incentiveGrant.commonObj.agMaterialUnitList.find(agMaterialUnitList => agMaterialUnitList.value === parseInt(item.unit_id))
        const agMaterialUnitListObjData = {}
        if (typeof agMaterialUnitListObj !== 'undefined') {
          agMaterialUnitListObjData.unit = agMaterialUnitListObj.text_en
          agMaterialUnitListObjData.unit_bn = agMaterialUnitListObj.text_bn
        } else {
          agMaterialUnitListObjData.unit = ''
          agMaterialUnitListObjData.unit_bn = ''
        }
        return Object.assign({}, item, matTypeObjData, matObjData, cropListObjData, agMaterialUnitListObjData)
      })
      return listData
    },
    async createData () {
      this.loading = true
      let result = null
      if (this.formData.id) {
        // formData.append('_method', 'POST')
        result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${districtApprovalCommitteeResolutionUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(incentiveGrantServiceBaseUrl, districtApprovalCommitteeResolutionStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/incentive-grant-service/dae/grant-allocation-distribution/district-approval-committee-resolution')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
  }
  }
}
</script>
